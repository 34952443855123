@import "reset-material";
@import "reset-swiper";
@import "mixins";

body {
  background: #F5F5F5;
}

ul {
  list-style-type: none;
  padding-left: 0;
}

textarea {
  resize: none;
}

.btn-save {
  width: 100%;
  @include btn-submit();
}

.btn-cancel {
  @include cancel-button();
  margin-top: 10px;
}

.btn-save-additional{
  @include cancel-button();
  margin-top: 26px;
  color: $main-color;
}


input, button, a {
  &:focus, &:hover, &:active, &:visited {
    outline: none;
  }
}

.slider {
  background: #EDEBEB;
  border-radius: 30px;
  width: 44px;
  height: 24px;
  padding: 3px;
}

.icon-copy {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.item-page {
  width: 100%;
  background: #F5F5F5;
}

.header {
  background: white;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}


