@import "./../../assets/styles/variables";

.swiper-pagination-bullet {
  background: #9E8E94;
  margin: 0 6px;
  width: 6px;
  height: 6px;
  opacity: 1;
  &-active{
    background: #D90F4F;
  }
}

.swiper-pagination-fraction, .swiper-pagination-custom,
.swiper-container-horizontal > .swiper-pagination-bullets {
  width: fit-content;
  left: 0;
  right: 0;
  margin: 0 auto;
  background: $white;
  border-radius: 4px;
  justify-content: center;
  display: flex;
  padding: 4px 6px;
}
.swiper-container {
  width: 100%;
  height: 300px;
  margin: 50px auto;
}

.swiper-slide {
  background: #f1f1f1;
  color: #000;
  text-align: center;
  line-height: 300px;
}
.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet{
  margin: 0 6px;

}
.swiper-button-prev.swiper-button-disabled, .swiper-button-next.swiper-button-disabled{
  opacity: 0;
}

.swiper-button-next:after{
  background: url("./../../assets/img/next-img.svg");
  background-size: 40px;
  content: '';
  width: 40px;
  height: 40px;
}

.swiper-button-next, .swiper-button-prev, .swiper-container-rtl .swiper-button-prev{
  right: 0;
  width: 40px;
  height: 40px;
}

.swiper-button-prev:after, .swiper-button-prev{
  width: 40px;
  height: 40px;
  left: 0;
}

.swiper-button-prev:after, .swiper-container-rtl .swiper-button-next:after{
  background: url("./../../assets/img/prev-img.svg");
  content: '';
}
