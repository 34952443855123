@import '~swiper/swiper-bundle';
@import "assets/styles/general";

* {
  margin: 0;
  box-sizing: border-box;
  font-family: 'Rubik', sans-serif !important;
}


html, body {
  height: 100%;
}










