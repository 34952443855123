mat-slide-toggle.mat-slide-toggle {
  background: #EDEBEB;


  .mat-slide-toggle-thumb {
    background: #9E8E94;
    border-radius: 30px;
    width: 18px;
    height: 18px;
    margin: 0;
  }

  .mat-slide-toggle-bar {
    background: none;
  }

  .mat-slide-toggle-thumb-container {
    width: 18px;
    height: 18px;
    top: -5px;
  }

  &.mat-checked {
    .mat-slide-toggle-thumb-container {
      transform: translate3d(19px, 0, 0);

      .mat-slide-toggle-thumb {
        background: #D90F4F;
      }
    }

    .mat-slide-toggle-bar {
      background: none;
    }
  }
}

.mat-form-field-appearance-fill .mat-form-field-flex {
  background: none;
  padding: 0 !important;

  .mat-body, .mat-body-1, .mat-typography {
    font-size: 14px;
    line-height: 22px;
    color: #000000;
  }
}

.mat-form-field-underline {
  display: none;
}

.cdk-overlay-pane {
  transform: translateY(20px);
  border-radius: 0;

  mat-dialog-container {
    max-height: calc(100vh - 50px);
    padding: 0 !important;
  }
}

.mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 0;
  top: -19px;
}

th.mat-header-cell {
  border-bottom-color: transparent;
}

.mat-select-value, .mat-option {
  color: #4E3E43;
}

.mat-snack-bar-container{
  background: none;
  padding: 0;
  box-shadow: none;
}

.mat-menu {
  .block-unlock-user {
    color: #D90F4F;
  }
  .assign{
    color: #5A5A5A;
  }
}
.cdk-overlay-pane{
  .mat-menu-panel{
    min-height: 0;
    .mat-menu-content:not(:empty){
      padding: 0;
    }
  }
}
#filter{
  .mat-form-field-infix{
    border-top: none;
    padding: 0;
  }
  .mat-form-field-label-wrapper{
    display: none;
  }
  .mat-form-field-appearance-fill .mat-select-arrow-wrapper{
    transform: none;
  }
  .mat-form-field-wrapper{
    padding-bottom: 0;
    .mat-form-field-infix{
      width: 132px;
    }
  }
  .mat-select-arrow{
    color: #000000;
  }
  .mat-form-field-appearance-legacy .mat-form-field-wrapper {
    top: 0;
  }
}
.mat-input-element{
  caret-color: #000000;
}
.mat-primary .mat-option.mat-selected:not(.mat-option-disabled){
  color: #5A5A5A;
}
.mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple){
  background: #FCFCFC;
}
.mat-option-text{
  display: flex !important;
  justify-content: space-between;
}

#create-company, #create-user, #assign-user{
  .mat-form-field-infix{
    border-top: none;
    padding: 0;
  }
  .mat-select{
        width: 98%;
      }
  .mat-form-field-label-wrapper{
    display: none;
  }
  .mat-form-field-appearance-fill .mat-select-arrow-wrapper{
    transform: none;
  }
  .mat-form-field-wrapper{
    padding-bottom: 0;
    .mat-form-field-infix{
      width: 132px;
    }
  }
  .mat-primary .mat-option.mat-selected:not(.mat-option-disabled){
    color: #5A5A5A;
  }
  .mat-select-arrow{
    color: #000000;
  }
  .mat-form-field-appearance-legacy .mat-form-field-wrapper {
    top: 0;
  }
}
#user-list{
  .user-list{
    .table-containers{
      table{
        tbody{
          tr:last-child{
            display: none;
          }
        }
      }
    }
  }
}
