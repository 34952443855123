@import "variables";
@import "./../../assets/styles/variables";



@mixin simple-modal() {
  background: $white;
  box-shadow: 0 6px 30px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 24px;
  .modal-title {
    font-weight: 500;
    font-size: 32px;
    line-height: 48px;
    color: $h2-color;
    margin-top: 0;
    margin-bottom: 24px;
    width: 100%;
    text-align: center;
  }
  .top-fields{
    field-item-name{

    }
  }
  .field-item, .top-fields{
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    margin-top: 0;
    min-width: 100%;
    width: 100%;
    &.for-name{
      min-width: 75%;
      width: 75%;
    }
    &.certificates{
      min-width: 22%;
      width: 22%;
    }
    &.last {
      margin-bottom: 0;
    }

    &.half-part {
      width: calc(50% - 8px);
      min-width: 40%;

    }
    &.not-active{
      border: 1px solid #E3E3E3;
    }

    &.with-trash {
      width: calc(50% - 40px);
      min-width: 40%;

      div {
        margin-bottom: 0;
      }
    }

    .field-slide {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      min-height: 46px;
      input{
        width: 70%;
      }
    }

    > span {
      color: #9E8E94;
      font-weight: 700;
      font-size: 12px;

      &.required::after {
        content: '*';
        color: $main-color;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }
    .title-disable{
      color: #BABABA;
    }

    .not-active{
      border: 1px solid #E3E3E3;
      &:hover{
        border: 1px solid #E3E3E3;
      }
    }

    input, textarea, mat-form-field {
      color: #4E3E43;
      background: $white;
      border: 1px solid #D1C5C9;
      border-radius: 4px;
      font-weight: 400;
      font-size: 16px;
      padding: 13px 0px 13px 13px;
      line-height: 18px;
      transition: all ease 0.3s;
      outline: none;
      caret-color: $main-color;

      &.is-invalid {
        height: 100%;
        @include is-invalid();
      }

      &::placeholder{
        color: #BABABA;

    }

      &:hover {
        border: 1px solid #807176;
      }

      &:active {
        background: #FEF7FA;
        border: 1px solid #E66391;
      }

      &:focus {
        background: #FEF7FA;
        border: 1px solid #E66391;
      }
    }
  }
  .modal-actions {
    margin-top: 48px;

    button {
      width: 100%;
      text-align: center;
    }
  }
}

@mixin btn-submit() {
  height: 48px;
  background: $main-color;
  border-radius: 4px;
  color: $white;
  border: 1px solid $main-color;
  cursor: pointer;
  transition: all ease 0.3s;
  padding: 13px;
  font-size: 16px;
  line-height: 22px;
  font-weight: 700;
  font-family: 'Rubik', sans-serif;
  &:hover {
    background: #E92462;
    color: $white;
  }
  &:active {
    background: #BF0C45;
  }
  &.half-part {
    width: calc(50% - 8px);
    min-width: 40%;

  }
}

@mixin field-item-input() {
  background: $white;
  border: 1px solid #D1C5C9;
  border-radius: 4px;
  font-weight: 400;
  font-size: 16px;
  padding: 13px 0px 13px 13px;
  line-height: 18px;
  transition: all ease 0.3s;
  outline: none;
  caret-color: $main-color;
  &:hover {
    border: 1px solid #807176;
  }
  &:active {
    background: #FEF7FA;
    border: 1px solid #E66391;
  }
  &:focus {
    background: #FEF7FA;
    border: 1px solid #E66391;
  }
}

@mixin is-invalid() {
  font-size: 16px;
  line-height: 24px;
  color: #D9630E;
  background: rgba(217, 99, 14, 0.1);
  border: 1px solid rgba(217, 99, 14, 0.6);
  box-sizing: border-box;
  border-radius: 4px;
}

@mixin error() {
  font-weight: 400;
  color: #D9630E;
  font-size: 12px;
  line-height: 22px;
  position: relative;
  margin-top: 4px;
  display: flex;
  justify-content: center;
  align-items: center;

  &::before {
    content: "";
    background-image: url("./../../assets/img/x-circle.svg");
    width: 16px;
    height: 16px;
    background-repeat: no-repeat;
    background-size: 16px;
    display: block;
    margin-right: 5px;
  }
}

@mixin add-plus() {
  font-size: 16px;
  line-height: 26px;
  color: #D90F4F;
  position: relative;
  font-weight: 400;
  margin-top: 12px;
  border: 0;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &::before {
    content: '';
    width: 16px;
    height: 16px;
    background-repeat: no-repeat;
    background-size: 16px;
    display: block;
    margin-right: 6px;
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask: url('./../../assets/img/plus-circle.svg') no-repeat 50% 50%;
    mask: url('./../../assets/img/plus-circle.svg') no-repeat 50% 50%;
    background-color: #D90F4F;

  }
  &:hover {
    color: #CA0C48;
    text-decoration: underline;

    &:before {
      background-color: #CA0C48;
    }
  }
  &:active{
    color: #4F4448;
    text-decoration: underline;

    &:before{
      background-color: #4F4448;
    }
  }

}

@mixin field-item() {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 100%;
  padding: 8px 0;
}

@mixin field-item-text() {
  color: #9E8E94;
  font-weight: 700;
  font-size: 12px;

}

@mixin cancel-button() {
  height: 48px;
  font-weight: 700;
  color: #625C5E;
  border-radius: 4px;
  cursor: pointer;
  transition: all ease 0.3s;
  padding: 13px;
  text-decoration: none;
  background: transparent;
  border: none;
  font-size: 16px;
  line-height: 22px;
  &:hover {
    color: #2E2B2C;
    text-decoration: underline;
  }
  &:active {
    color: #201D1E;
    text-decoration: underline;
  }
  &.half-part {
    width: calc(50% - 8px);
    min-width: 40%;

  }
}

@mixin create-form-name() {
  font-weight: 500;
  font-size: 32px;
  line-height: 48px;
  color: $h2-color;
  margin-top: 0;
  margin-bottom: 0;
  width: 100%;
  text-align: center;
}

@mixin alert-message(){
  position: absolute;
  top: 50px;
  left: 0;
  right: 0;
  z-index: 10;
  margin: 0 auto;
  box-shadow: 0 6px 22px rgba(68, 162, 104, 0.2);
  border-radius: 4px;
  width: 295px;
  display: flex;
  align-items: center;
  padding: 10px 10px 10px 38px;
  font-size: 14px;
  line-height: 22px;

  &:before {
    content: '';
    position: absolute;
    top: 11px;
    left: 12px;
    height: 20px;
    width: 20px;
  }
}

